
export enum DialogContentType {
  DONE,
  INFO,
  ERROR,
  WARN,
  CONFIRM
}

export interface DialogContentData {
  title?: string;
  message: string;
  confirmText: string;
  cancelText?: string;
  type: DialogContentType;
}

export enum DialogCloseResult {
  NO,
  YES,
}
