import { Router } from '@angular/router';

/* eslint-disable @typescript-eslint/no-explicit-any */
export class Util {
  static FormatString(str: string, ...val: string[]): string {
    for (let index = 0; index < val.length; index++) {
      str = str.replace(`{${index}}`, val[index]);
      if (index === 0)
        str = str.replace(`{$INTERPOLATION}`, val[index])
      else
        str = str.replace(`{$INTERPOLATION_${index}}`, val[index]);
    }
    return str;
  }

  private static $localizeId(messageParts: TemplateStringsArray, ...expressions: any[]): string {
    // Create writeable copies
    const messagePartsCopy: any = [...messageParts];
    const messagePartsRawCopy = [...messageParts.raw];

    // Strip trans-unit-id element
    const prefix = messagePartsCopy.shift();
    const prefixRaw = messagePartsRawCopy.shift();
    const transUnitId = expressions.shift();

    // Re-add prefix and replace :TRANSUNITID: with transUnitId.
    messagePartsCopy[0] = prefix + messagePartsCopy[0].replace(':TRANSUNITID:', `:@@${transUnitId}:`);
    messagePartsRawCopy[0] = prefixRaw + messagePartsRawCopy[0].replace(':TRANSUNITID:', `:${transUnitId}:`);

    // Create messageParts object
    Object.defineProperty(messagePartsCopy, 'raw', { value: messagePartsRawCopy });

    // Call original localize function
    return $localize(messagePartsCopy as TemplateStringsArray, ...expressions);
  }

  static GetMessageLocalize(id: string, defaultValue?: string): string {
    if (!$localize.translate) return defaultValue ?? id;

    return this.$localizeId`${id}:TRANSUNITID:${defaultValue ?? id}`;
  }

  static getTranslation(key: string) : string {
    return this.GetMessageLocalize(key);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static isNumber(value: any): value is number {
    return !isNaN(parseInt(value));
  }

  static padNumber(value: number, lenght = 2) {
    if (this.isNumber(value)) {
      return `0${value}`.slice(-1 * lenght);
    } else {
      return '';
    }
  }

  static mapDataWithColumns(data: any[], columns?: string[]): any[] {
    const table: object[] = [];
    let tempRow: any;

    if (columns) {
      data.forEach(row => {
        tempRow = {};
        columns.forEach((key) => {
          tempRow[key] = row[key];
        });
        table.push(tempRow);
      });
      return table;
    }
    return data;
  }

  static OpenInNewTab(router: Router, namedRoute: string, queryParams?: any) {
    const newRelativeUrl = router.serializeUrl(
      router.createUrlTree([namedRoute]
        , { queryParams: { ...queryParams } }));

    window.open(newRelativeUrl, '_blank');
  }

  static gotoUrl(router: Router, namedRoute: string, queryParams?: any) {
    const newRelativeUrl = router.serializeUrl(
      router.createUrlTree([namedRoute]
        , { queryParams: { ...queryParams } }));

    router.navigateByUrl(newRelativeUrl);
  }

  static OpenCurrentRouter(router: Router, namedRoute: string, queryParams?: any) {
    if(!router.url.includes(namedRoute)) {
      router.navigate([namedRoute], {queryParams: queryParams})
    } else {
      router.navigateByUrl('/', { skipLocationChange: true })
        .then(() => router.navigate([namedRoute], {queryParams: queryParams}));
    }
  }

  static removeAccent(str: string): string {
    str = str.toLowerCase();
    //     We can also use this instead of from line 11 to line 17
    //     str = str.replace(/\u00E0|\u00E1|\u1EA1|\u1EA3|\u00E3|\u00E2|\u1EA7|\u1EA5|\u1EAD|\u1EA9|\u1EAB|\u0103|\u1EB1|\u1EAF|\u1EB7|\u1EB3|\u1EB5/g, "a");
    //     str = str.replace(/\u00E8|\u00E9|\u1EB9|\u1EBB|\u1EBD|\u00EA|\u1EC1|\u1EBF|\u1EC7|\u1EC3|\u1EC5/g, "e");
    //     str = str.replace(/\u00EC|\u00ED|\u1ECB|\u1EC9|\u0129/g, "i");
    //     str = str.replace(/\u00F2|\u00F3|\u1ECD|\u1ECF|\u00F5|\u00F4|\u1ED3|\u1ED1|\u1ED9|\u1ED5|\u1ED7|\u01A1|\u1EDD|\u1EDB|\u1EE3|\u1EDF|\u1EE1/g, "o");
    //     str = str.replace(/\u00F9|\u00FA|\u1EE5|\u1EE7|\u0169|\u01B0|\u1EEB|\u1EE9|\u1EF1|\u1EED|\u1EEF/g, "u");
    //     str = str.replace(/\u1EF3|\u00FD|\u1EF5|\u1EF7|\u1EF9/g, "y");
    //     str = str.replace(/\u0111/g, "d");
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
    str = str.replace(/đ/g, 'd');
    // Some system encode vietnamese combining accent as individual utf-8 characters
    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ''); // Huyền sắc hỏi ngã nặng
    str = str.replace(/\u02C6|\u0306|\u031B/g, ''); // Â, Ê, Ă, Ơ, Ư
    return str;
  }

  static randomIntFromInterval(max = 100, min = 0) {
    return Math.floor(Math.random() * (max - min + 1) + min)
  }
}
