import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule, NgIf } from '@angular/common';
import { DialogCloseResult, DialogContentData, DialogContentType } from './dialog.model';

@Component({
  selector: 'app-modal-content',
  standalone: true,
  imports: [NgIf, CommonModule],
  template: `
		<div class="modal-body">
      <div class="row">
        <div style="display: flex; justify-content: center;">
          <span [ngSwitch]="data.type">
            <span class="material-icons text-warning" *ngSwitchCase="DialogContentType.WARN" style="font-size: 64px; width: 64px;" >report_outline</span>
            <span class="material-icons text-danger" *ngSwitchCase="DialogContentType.ERROR" style="font-size: 64px; width: 64px;" >dangerous_outline</span>
            <span class="material-icons text-success" *ngSwitchCase="DialogContentType.DONE" style="font-size: 64px; width: 64px;">done</span>
            <span class="material-icons text-secondary" *ngSwitchCase="DialogContentType.CONFIRM" style="font-size: 64px; width: 64px;">question_mark</span>
            <span class="material-icons text-secondary" *ngSwitchDefault style="font-size: 64px; width: 64px;">info_outline</span>
          </span>
        </div>

        <div style="display: flex; justify-content: center;">
          <h1 *ngIf="data.title" class="align-self-center">{{data.title}}</h1>
        </div>

        <div style="display: flex; justify-content: center; padding-top: 10px;">
          <p *ngIf="data.message" [innerHTML]="data.message"></p>
        </div>

        <div style="display: flex; justify-content: center; padding-top: 10px;">
          <button
            type="button"
            class="btn btn-secondary"
            style="margin-right: 6px; min-width: 60px;"
            (click)="activeModal.close(DialogResult.NO)"
            *ngIf="data.cancelText">
            {{data.cancelText}}
          </button>
          <button
            type="button"
            class="btn btn-primary"
            style="margin-right: 6px; min-width: 60px;"
            (click)="activeModal.close(DialogResult.YES)"
            *ngIf="data.confirmText">
            {{data.confirmText}}
          </button>
        </div>
      </div>

		</div>
	`,
})
export class DialogContentComponent {
  @Input() data!: DialogContentData;

  DialogContentType = DialogContentType;
  DialogResult = DialogCloseResult;
  constructor(public activeModal: NgbActiveModal) { }
}
