import { sha3_256 } from 'js-sha3';

const algorithm = { name: 'ECDH', namedCurve: 'P-521' };

export const GenerateKeyPair = async () =>
  await window.crypto.subtle.generateKey(algorithm, true, ['deriveBits']);

export const GetPublicKey = async (keyPair: CryptoKeyPair) =>
  Buffer.from(
    await window.crypto.subtle.exportKey('spki', keyPair.publicKey)
  ).toString('hex');

export const GetSharedKey = async (
  keyPair: CryptoKeyPair,
  remotePublicKeyString: string
) => {
  const remotePublicKeyBytes = Buffer.from(remotePublicKeyString, 'hex');
  const remotePublicKey = await window.crypto.subtle.importKey(
    'spki',
    remotePublicKeyBytes,
    algorithm,
    true,
    []
  );
  const sharedSecret = await window.crypto.subtle.deriveBits(
    { name: 'ECDH', public: remotePublicKey },
    keyPair.privateKey,
    528
  );
  return sha3_256(Buffer.from(sharedSecret).toString('hex'));
};
