export type EncodeFn = (data: string) => ArrayBuffer;
export type DecodeFn = (data: ArrayBuffer) => string;

export const ToHex: DecodeFn = (buffer: ArrayBuffer): string =>
  Array.from(new Uint8Array(buffer))
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('');
export const FromHex: EncodeFn = (hex: string): ArrayBuffer =>
  new Uint8Array(
    Array.from({ length: hex.length / 2 }, (_, i) =>
      parseInt(hex.substring(i * 2, i * 2 + 2), 16)
    )
  ).buffer;
export const ToUtf8: DecodeFn = (buffer: ArrayBuffer): string =>
  new TextDecoder().decode(new Uint8Array(buffer));
export const FromUtf8: EncodeFn = (str: string): ArrayBuffer =>
  new TextEncoder().encode(str).buffer;
